import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
        Candle Making Workshop
        </p>
        <p>
        Sunday 21st January, 2pm - Earl of East Soho
        </p>
        <p>
        Happy Anniversary ❤️
        </p>
      </header>
    </div>
  );
}

export default App;
